import "./emp-text-input.scss";

import React, { FC, useEffect, useRef, useState } from "react";

import { Color } from "../../../utilities/colors";
import { FormControl } from "../../../utilities/formUtils/formControl";
import AlertSquareIcon from "../../icon/alert-square";
import _debounce from "lodash/debounce";
import { IconProps } from "../../../model/common/icon";
import EyeIcon from "../../icon/eye-icon";
import EyeOffIcon from "../../icon/eye-off-icon";

interface Props {
  className?: string;
  style?: React.CSSProperties;
  characterCount?: number;
  type?:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week"
    | "textarea";
  enterKeyHint?: "done" | "go" | "next" | "search";
  showPassword?: boolean;
  autocomplete?: boolean;
  letterSpacing?: number;
  labelText?: string | JSX.Element;
  value?: string;
  description?: string;
  placeholder?: string;
  initialValue?: string;
  optional?: boolean;
  backgroundColor?: string;
  multiline?: boolean;
  required?: boolean;
  onChange?: (formControl: FormControl) => void;
  onKeypress?: (key: string) => void;
  onFocus?: () => void;
  // EmpTextInput Form Integration
  id: string;
  formControl: FormControl;
  leftIconComponent?: FC<IconProps>;
  leftIconSize?: number;
  rightIcon?: React.ReactElement;
  suppressErrorMessage?: boolean;
  formValidation?: () => void;
  textAreaAdaptiveHeight?: boolean;
  disabled?: boolean;
  compressedLabel?: boolean;
  rows?: number;
}
const EmpTextInput = (props: Props) => {
  const {
    enterKeyHint,
    leftIconComponent: LeftIconComponent,
    showPassword,
    onChange,
    onFocus,
    style,
    required,
    description,
    className,
    id,
    labelText,
    placeholder,
    formControl,
    multiline,
    characterCount,
    optional,
  } = props;
  const suppressErrorMessage = props.suppressErrorMessage ?? false;
  const [errorMessage, setErrorMessage] = useState<string>();
  const value = formControl.getValue();
  const type = props.type ? props.type : "text";
  const isMultiline = multiline ? multiline : false;
  const textAreaAdaptiveHeight = props.textAreaAdaptiveHeight ?? false;
  const isRequired = required ? required : false;
  const isOptional = optional ? optional : false;
  const disabled = props.disabled ?? false;
  const autocomplete = props.autocomplete ?? true;
  const compressedLabel = props.compressedLabel ?? false;
  const leftIconSize = props.leftIconSize ?? 14;
  const rows = props.rows ?? 4;
  const [currentCharacterCount, setCurrentCharacterCount] = useState<number>(
    value.length
  );
  const [currentShowPassword, setCurrentShowPassword] = useState(false);
  const [isFocused, setFocused] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    setErrorMessage(formControl.errorMessage);
  }, [formControl.errorMessage]);

  useEffect(() => {
    if (isMultiline) {
      textareaRef.current!.value = formControl.getValue();
      if (textAreaAdaptiveHeight) autoResizeTextArea();
    } else {
      inputRef.current!.value = formControl.getValue();
    }
  }, [formControl.resetFlag]);

  const handleOnChange = (value: string) => {
    formControl.setValue(value);
    // Propagatethis change to the parent component
    if (onChange) onChange(formControl);
  };

  const updateCharacterCount = _debounce((value: string) => {
    setCurrentCharacterCount(value.length);
  }, 200);

  const toggleShowPassword = () => {
    setCurrentShowPassword(!currentShowPassword);
  };

  function autoResizeTextArea() {
    textareaRef.current!.style.height = "auto"; // Reset the height to auto
    textareaRef.current!.style.height =
      textareaRef.current!.scrollHeight + "px"; // Set the height to match the content
  }

  return (
    <div className={`emp-input-wrapper ${className}`} style={style}>
      <label
        className={`${compressedLabel ? "compressed" : ""} ${
          description ? "mb-1" : "mb-2"
        }`}
        htmlFor={id}
      >
        {labelText}
        {isRequired && <span className="required">*</span>}
        {isOptional && <span className="optional">Optional</span>}
      </label>
      {description && <p>{description}</p>}
      <div
        className={`emp-text-input-wrapper ${isFocused ? "focused" : ""} ${
          disabled ? "disabled" : ""
        }`}
      >
        {LeftIconComponent && (
          <div className="emp-left-icon-wrapper">
            <LeftIconComponent
              size={leftIconSize}
              backgroundColor={
                isFocused ? Color.PRIMARY[550] : Color.NEUTRAL[300]
              }
            />
          </div>
        )}
        {!isMultiline && (
          <input
            ref={inputRef}
            enterKeyHint={enterKeyHint ?? undefined}
            className={`emp-text-input ${
              LeftIconComponent ? "left-icon" : ""
            } `}
            style={{
              letterSpacing: props.letterSpacing
                ? props.letterSpacing
                : undefined,
            }}
            placeholder={placeholder}
            autoComplete={autocomplete ? "on" : "off"}
            defaultValue={value}
            disabled={disabled}
            onKeyDown={(e) => props.onKeypress?.(e.key)}
            type={!currentShowPassword ? type : "text"}
            onFocus={() => {
              setFocused(true);
              if (onFocus) onFocus();
            }}
            onBlur={() => {
              setFocused(false);
            }}
            // Callbacks
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleOnChange(event.target.value);
            }}
          />
        )}
        {isMultiline && (
          <textarea
            ref={textareaRef}
            className="emp-text-input"
            placeholder={placeholder}
            style={{
              backgroundColor: props.backgroundColor
                ? props.backgroundColor
                : "undefined",
            }}
            defaultValue={value}
            disabled={disabled}
            rows={rows}
            onKeyDown={(e) => props.onKeypress?.(e.key)}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              handleOnChange(event.target.value);
              updateCharacterCount(event.target.value);
            }}
            onInput={() => {
              if (textAreaAdaptiveHeight) autoResizeTextArea();
            }}
            onFocus={() => {
              setFocused(true);
              if (onFocus) onFocus();
            }}
            onBlur={() => {
              setFocused(false);
            }}
          />
        )}
        {showPassword && (
          <div
            className="emp-right-icon-wrapper"
            onClick={() => {
              toggleShowPassword();
            }}
          >
            {!currentShowPassword ? (
              <EyeIcon size={18} backgroundColor={Color.NEUTRAL[500]} />
            ) : (
              <EyeOffIcon size={18} backgroundColor={Color.NEUTRAL[500]} />
            )}
          </div>
        )}
      </div>

      <div className="actions-wrapper">
        <div className=" emp-error-wrapper">
          {errorMessage && !suppressErrorMessage && (
            <div className="emp-error-message-wrapper">
              <AlertSquareIcon
                backgroundColor={Color.RED[600]}
                size={16}
                bottom={1}
              />
              <span>{errorMessage}</span>
            </div>
          )}
        </div>
        {characterCount && (
          <div className="emp-text-input-character-count-wrapper">
            <span className="emp-text-input-character-count">{`${currentCharacterCount}/${characterCount}`}</span>
          </div>
        )}
      </div>
    </div>
  );
};
export default EmpTextInput;
