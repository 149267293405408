import axios from "axios";
// import aws4 from "aws4";
import { ENDPOINTS } from "../constants";
import { ApiResponse } from "../model/api/api-response";
import { ApiStatus } from "../model/api/api-status";
import { EnquiryDto } from "../model/enquiry/enquiry.dto";
import { EmpInterceptor } from "./emp-interceptor";

// Configure Error Interceptor
EmpInterceptor.configureInterceptor(axios);

const EnquiryAPI = {
  createEnquiry: async (
    request: EnquiryDto
  ): Promise<ApiResponse<ApiStatus>> => {
    const response = await axios.post(ENDPOINTS.enquiry.lambdaUrl, request);
    EmpInterceptor.handleError(response);

    return response.data;
  },
};

export default EnquiryAPI;
