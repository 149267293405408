import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import EmpLandingNavbar from "./components/template/emp-landing-navbar";
import WaitlistPage from "./pages/waitlist-page";
import BlankPage from "./components/template/blank-page/blank-page";
import ContactUsPage from "./pages/contact-us-page";
import EmpFooter from "./components/template/emp-footer";
import EmpToastManager from "./components/shared/emp-toast-manager";
import NotFoundPage from "./pages/404-page";

const EnterpriseInterestPage = lazy(
  () => import("./pages/enterprise-interest-page")
);
const LandingPage = lazy(() => import("./pages/landing-page"));
const LegalPage = lazy(() => import("./pages/legal-page"));
// const PrivacyPolicyPage = lazy(() => import("./pages/legal-page"));
const FeaturesPage = lazy(() => import("./pages/features-page"));
const AboutUsPage = lazy(() => import("./pages/about-us-page"));
const PricingPage = lazy(() => import("./pages/pricing-page"));
const BlogsPage = lazy(() => import("./pages/blogs-page"));
const BlogPostPage = lazy(() => import("./pages/blog-post-page"));

function App() {
  return (
    <>
      <div className="app-page-wrapper">
        <BrowserRouter>
          <EmpToastManager />
          {/* <EmpToast title={'Bad Request'} description={'Company Name is required'} id={'123'} onComplete={() => { console.log("done") }} /> */}
          <EmpLandingNavbar />
          <Suspense fallback={<BlankPage />}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="about-us" element={<AboutUsPage />} />
              <Route path="blogs" element={<BlogsPage />} />
              <Route path="blog/:id" element={<BlogPostPage />} />
              <Route path="contact-us" element={<ContactUsPage />} />
              <Route
                path="enterprise-interest"
                element={<EnterpriseInterestPage />}
              />
              <Route path="features" element={<FeaturesPage />} />
              <Route path="legal" element={<LegalPage />} />
              <Route path="pricing" element={<PricingPage />} />
              <Route path="waitlist" element={<WaitlistPage />} />
              <Route path="/404" element={<NotFoundPage />} />
              <Route path="*" element={<Navigate to="/404" replace />} />
            </Routes>
          </Suspense>
          <EmpFooter />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
