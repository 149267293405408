import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { ReactComponent as ChevronIcon } from "../../../../assets/icons/chevron-down.svg";
import { ReactComponent as FileIcon } from "../../../../assets/icons/file-heart.svg";
import { ReactComponent as HelpIcon } from "../../../../assets/icons/help-square.svg";
import { ReactComponent as MessageIcon } from "../../../../assets/icons/message-question-square.svg";

const NavbarPopover = () => {
  return (
    <div className="resources-popover">
      <HashLink smooth to="/pricing#Pricing-FAQ-Section">
        <div className="popover-items-wrapper">
          <div className="popover-left-contain">
            <div className="popover-icon-wrapper">
              <MessageIcon width={18} height={18} />
            </div>
            <div className="popover-content-wrapper">
              <b className="popover-title">Pricing FAQs</b>
              <p className="popover-subtitle">
                Find out our platform pricing regulations, and have your queries
                answered!
              </p>
            </div>
          </div>
          <div className="popover-chevron-icon-wrapper">
            <ChevronIcon width={16} height={16} />
          </div>
        </div>
      </HashLink>
      <HashLink smooth to="/blogs#">
        <div className="popover-items-wrapper">
          <div className="popover-left-contain">
            <div className="popover-icon-wrapper">
              <FileIcon width={18} height={18} />
            </div>
            <div className="popover-content-wrapper">
              <b className="popover-title">Blogs</b>
              <p className="popover-subtitle">
                Stay informed of anything and everything about marketing
              </p>
            </div>
          </div>
          <div className="popover-chevron-icon-wrapper">
            <ChevronIcon width={16} height={16} />
          </div>
        </div>
      </HashLink>

      <Link to="https://help.emplifive.com" target="_blank">
        <div className="popover-items-wrapper">
          <div className="popover-left-contain">
            <div className="popover-icon-wrapper">
              <HelpIcon width={18} height={18} />
            </div>
            <div className="popover-content-wrapper">
              <b className="popover-title">Self Help Resources</b>
              <p className="popover-subtitle">
                Learn how to maximise your usage on Emplifive
              </p>
            </div>
          </div>
          <div className="popover-chevron-icon-wrapper">
            <ChevronIcon width={16} height={16} />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default NavbarPopover;
