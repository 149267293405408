import { motion } from "framer-motion";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./emp-button.scss";

type ButtonStyleType = "primary" | "secondary" | "danger" | "secondary-dark";
interface Props {
  text: string;
  buttonStyle?: ButtonStyleType;
  onSubmit?: () => void;
  disabled?: boolean;
  isFullWidth?: boolean;
  className?: string;
  buttonHeight?: "sm" | "md" | "lg" | "xl";
}

const buttonTextVariants = {
  default: { opacity: 1 },
  loading: { opacity: 0 },
};

const spinnerVariants = {
  default: { opacity: 0 },
  loading: { opacity: 1 },
};
type EmpButtonVariantTypes = "default" | "loading";

export interface EmpButtonRef {
  setButtonState: (buttonState: EmpButtonVariantTypes) => void;
  click: () => void;
}
const EmpButton = forwardRef((props: Props, ref) => {
  const { text, onSubmit } = props;
  const [buttonVariant, setButtonVariant] =
    useState<EmpButtonVariantTypes>("default");
  const [buttonStyle, setButtonStyle] = useState<ButtonStyleType>(
    props.buttonStyle ? props.buttonStyle : "primary"
  );
  const [disabled, setDisabled] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const fullWidth = props.isFullWidth ?? true;
  const buttonHeight = props.buttonHeight ?? "md";

  useEffect(() => {
    const isDisabled = props.disabled !== undefined ? props.disabled : false;
    setDisabled(isDisabled);
  }, [props.disabled]);

  useEffect(() => {
    setButtonStyle(props.buttonStyle ? props.buttonStyle : "primary");
  }, [props.buttonStyle]);

  useImperativeHandle(ref, () => {
    return {
      setButtonState,
      click,
    };
  });

  const setButtonState = (buttonState: EmpButtonVariantTypes) => {
    setButtonVariant(buttonState);
  };

  const click = () => {
    buttonRef.current?.click();
  };

  const heightStyleMapper = (buttonHeight: "sm" | "md" | "lg" | "xl") => {
    return `height-${buttonHeight}`;
  };

  return (
    <div
      className={`${fullWidth ? "full-width" : "fit-content"} ${
        props.className ?? ""
      }`}
    >
      <button
        ref={buttonRef}
        disabled={disabled}
        className={`emp-button ${buttonVariant} ${buttonStyle} ${heightStyleMapper(
          buttonHeight
        )}`}
        onClick={() => {
          if (onSubmit) onSubmit();
        }}
      >
        <motion.div
          className="emp-loader"
          variants={spinnerVariants}
          initial="default"
          animate={buttonVariant}
          transition={{ duration: 0.1 }}
        >
          <div className="emp-spinner"></div>
        </motion.div>
        <motion.span
          variants={buttonTextVariants}
          initial="default"
          animate={buttonVariant}
          transition={{ duration: 0.1 }}
        >
          {text}
        </motion.span>
      </button>
    </div>
  );
});
export default EmpButton;
