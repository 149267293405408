type EmpEnvironment = "staging" | "production" | "development"
export const getEnv = (): EmpEnvironment => {
    return process.env["REACT_APP_ENVIRONMENT"] as EmpEnvironment
}

export const ENV_CONSTANTS = {
    development: {
        adminMsvc: "http://localhost:8080/admin",
        emplifiveUrl: "https://localhost:3100"
    },
    staging: {
        adminMsvc: "https://staging-api.emplifive.com/admin",
        emplifiveUrl: "https://staging-app-web.emplifive.com/"
    },
    "staging-dev": {
        adminMsvc: "http://localhost:8080/admin",
        emplifiveUrl: "https://localhost:3100"
    },
    production: {
        adminMsvc: "https://api.emplifive.com/admin",
        emplifiveUrl: "https://platform.emplifive.com"
    },
}