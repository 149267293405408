



import { IconProps } from "../../model/common/icon"
import useEmpIcon from "../../hooks/useEmpIcon";

const ChevronDownIcon = (props: IconProps) => {

    const { size, top, left, right, bottom, backgroundColor, stroke } = useEmpIcon(props)
    return (
        <div style={{ position: "relative", top, left, right, bottom, display: "flex", alignItems: "center" }}>
            <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 9L12 15L18 9" stroke={backgroundColor} strokeWidth={stroke} strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </div >)
}
export default ChevronDownIcon