// Define the Hook with the IconProps type as an argument

import { useEffect } from "react";

const useScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
};
export default useScrollToTop;
