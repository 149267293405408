import PubSub from "pubsub-js";

import { PUB_SUB_TOPICS, ToastEnum } from "../constants";
/**
 * Custom implemented service to display alert toast when an event is triggered.
 */
const ToastUtils = {
  success: (title: string, description: string): void => {
    console.log("success toast");
    PubSub.publish(PUB_SUB_TOPICS.ALERT, {
      title,
      description,
      type: ToastEnum.SUCCESS,
    });
  },
  error: (title: string, description: string): void => {
    PubSub.publish(PUB_SUB_TOPICS.ALERT, {
      title,
      description,
      type: ToastEnum.ERROR,
    });
  },
};
export default ToastUtils;
