import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Color } from "../../../../utilities/colors";
import ChevronDownIcon from "../../../icon/chevron-down";
import NavbarPopover from "../navbar-popover";

export type EmpMenuItems = {
  to: string;
  text: string;
  mobileText: string;
  isNested: boolean;
  value: string;
};

interface Props {
  menuItem: EmpMenuItems;
  currentPath: string;
}

const NavbarMenuItem = (props: Props) => {
  const { menuItem: item, currentPath } = props;

  const popoverRef = useRef<HTMLDivElement>(null);
  const [popoverVisibility, setPopoverVisibility] = useState(false);

  return (
    <div
      className="menu-item-wrapper"
      onMouseLeave={() => {
        if (!item.isNested) return;
        setPopoverVisibility(false);
      }}
      onClick={() => {
        if (!item.isNested) return;
        setPopoverVisibility(false);
      }}
    >
      <li
        key={item.to}
        className={`menu-item ${item.value === currentPath ? "active" : ""}`}
        data-hover={item.to}
      >
        {item.isNested ? (
          <>
            <div
              className="text-div center"
              onMouseEnter={() => {
                setPopoverVisibility(true);
              }}
            >
              <span>{item.text}</span>
              {item.isNested && (
                <div
                  className={`chevron-wrapper ${
                    popoverVisibility ? "active" : ""
                  }`}
                >
                  <ChevronDownIcon
                    backgroundColor={
                      popoverVisibility
                        ? Color.NEUTRAL[800]
                        : Color.NEUTRAL[500]
                    }
                    strokeWidth={2.2}
                  />
                </div>
              )}
            </div>
            {item.isNested && (
              <div
                ref={popoverRef}
                className={`popover-wrapper ${
                  popoverVisibility ? "visible" : ""
                }`}
              >
                <NavbarPopover />
              </div>
            )}
          </>
        ) : (
          <Link to={item.to} className="text-div center">
            <span>{item.text}</span>
          </Link>
        )}
        <div className="hover-bar">
          <div className="bar"></div>
        </div>
      </li>
    </div>
  );
};

export default NavbarMenuItem;
