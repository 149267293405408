export const INTERESTS_AND_SERVICES_CONSTANTS = {
  interests: [
    {
      id: 3,
      label: "💃 Fashion",
    },
    {
      id: 4,
      label: "⛩️ Travel",
    },
    {
      id: 5,
      label: "🧗 Adventure",
    },
    {
      id: 7,
      label: "📷 Photography & Videography",
    },
    {
      id: 8,
      label: "🎮 Gaming & Esports",
    },
    {
      id: 9,
      label: "💄 Beauty & Makeup",
    },
    {
      id: 10,
      label: "💻 Technology",
    },
    {
      id: 11,
      label: "🎭 DIY & Crafts",
    },
    {
      id: 12,
      label: "🛋️ Interior Design",
    },
    {
      id: 13,
      label: "🎹 Music",
    },
    {
      id: 14,
      label: "🏟️ Sports",
    },
    {
      id: 15,
      label: "📚 Literature",
    },
    {
      id: 16,
      label: "🎨 Art & Painting",
    },
    {
      id: 17,
      label: "👪 Parenting",
    },
    {
      id: 18,
      label: "🏥 Health & Nutrition",
    },
    {
      id: 19,
      label: "🍀 Sustainability",
    },
    {
      id: 20,
      label: "🎭 Comedy",
    },
    {
      id: 21,
      label: "🔬 Science",
    },
    {
      id: 6,
      label: "🍲 Food",
    },
  ],
  services: [
    {
      id: 1,
      label: "⭐ Product Reviews",
    },
    {
      id: 2,
      label: "🎙️ Podcasting",
    },
    {
      id: 3,
      label: "🎤 Event Hosting",
    },
    {
      id: 4,
      label: "📹 Live Streaming",
    },
    {
      id: 5,
      label: "🎥 Video Production",
    },
    {
      id: 6,
      label: "📹 Blogging",
    },
    {
      id: 7,
      label: "👩‍🏫🏽 Online Teaching",
    },
    {
      id: 8,
      label: "🎞️ Social Media Post",
    },
    {
      id: 10,
      label: "📦 Unboxing",
    },
    {
      id: 9,
      label: "🎫 Event Appearance",
    },
  ],
};
