import { useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { Color } from "../../../../utilities/colors";
import ChevronDownIcon from "../../../icon/chevron-down";

export type EmpMenuItems = {
  to: string;
  text: string;
  mobileText: string;
  isNested: boolean;
  value: string;
};

interface Props {
  menuItem: EmpMenuItems;
  currentPath: string;
  toggleMobileMenu: () => void;
}
const NavbarMobileMenuItem = (props: Props) => {
  const { menuItem: item, currentPath, toggleMobileMenu } = props;
  const navigate = useNavigate();

  const [isSubMenuExpanded, setSubMenuExpanded] = useState(false);
  const subMenuRef = useRef<HTMLUListElement>(null);
  const isNested = useMemo(() => {
    return item.isNested;
  }, [item]);

  useEffect(() => {
    if (!subMenuRef.current) return;
    if (isSubMenuExpanded) {
      const children = subMenuRef.current.children;
      let childrenHeights = 0;
      for (var i = 0; i < children.length; i++) {
        childrenHeights += (children[i] as HTMLElement).offsetHeight;
      }
      subMenuRef.current.style.height = `${childrenHeights}px`;
    } else {
      subMenuRef.current.style.height = `0px`;
    }
  }, [isSubMenuExpanded]);
  return (
    <>
      <li
        key={item.to}
        onClick={() => {
          if (isNested) {
            setSubMenuExpanded(!isSubMenuExpanded);
            return;
          }
          navigate(item.to);
          toggleMobileMenu();
        }}
        className="menu-item"
      >
        <span className="color-gray-100">{item.mobileText}</span>
        {isNested && (
          <div
            className={`chevron-wrapper ${isSubMenuExpanded ? "active" : ""}`}
          >
            <ChevronDownIcon
              backgroundColor={
                isSubMenuExpanded ? Color.NEUTRAL[800] : Color.NEUTRAL[500]
              }
              strokeWidth={2.2}
            />
          </div>
        )}
      </li>
      {isNested && (
        <ul className="submenu-ul" ref={subMenuRef}>
          <li
            onClick={() => {
              navigate("/pricing#Pricing-FAQ-Section");
              toggleMobileMenu();
            }}
          >
            Pricing FAQs
          </li>
          <li
            onClick={() => {
              window.open("https://help.emplifive.com", "_blank");
              toggleMobileMenu();
            }}
          >
            Self Help Resources
          </li>
          <li
            onClick={() => {
              navigate("/blogs");
              toggleMobileMenu();
            }}
          >
            Blogs
          </li>
        </ul>
      )}
    </>
  );
};

export default NavbarMobileMenuItem;
