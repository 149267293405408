import { SelectOption } from "../model/common/selectOption";

export const countriesOptions: SelectOption[] = [
    { label: 'Åland Islands', value: 'AX' },
    { label: 'Albania', value: 'AL' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'AndorrA', value: 'AD' },
    { label: 'Angola', value: 'AO' },
    { label: 'Anguilla', value: 'AI' },
    { label: 'Antarctica', value: 'AQ' },
    { label: 'Antigua and Barbuda', value: 'AG' },
    { label: 'Argentina', value: 'AR' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Aruba', value: 'AW' },
    { label: 'Australia', value: 'AU' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaijan', value: 'AZ' },
    { label: 'Bahamas', value: 'BS' },
    { label: 'Bahrain', value: 'BH' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Barbados', value: 'BB' },
    { label: 'Belarus', value: 'BY' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Belize', value: 'BZ' },
    { label: 'Benin', value: 'BJ' },
    { label: 'Bermuda', value: 'BM' },
    { label: 'Bhutan', value: 'BT' },
    { label: 'Bolivia', value: 'BO' },
    { label: 'Bosnia and Herzegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bouvet Island', value: 'BV' },
    { label: 'Brazil', value: 'BR' },
    { label: 'British Indian Ocean Territory', value: 'IO' },
    { label: 'Brunei Darussalam', value: 'BN' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Burkina Faso', value: 'BF' },
    { label: 'Burundi', value: 'BI' },
    { label: 'Cambodia', value: 'KH' },
    { label: 'Cameroon', value: 'CM' },
    { label: 'Canada', value: 'CA' },
    { label: 'Cape Verde', value: 'CV' },
    { label: 'Cayman Islands', value: 'KY' },
    { label: 'Central African Republic', value: 'CF' },
    { label: 'Chad', value: 'TD' },
    { label: 'Chile', value: 'CL' },
    { label: 'China', value: 'CN' },
    { label: 'Christmas Island', value: 'CX' },
    { label: 'Cocos (Keeling) Islands', value: 'CC' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Comoros', value: 'KM' },
    { label: 'Congo', value: 'CG' },
    { label: 'Congo, The Democratic Republic of the', value: 'CD' },
    { label: 'Cook Islands', value: 'CK' },
    { label: 'Costa Rica', value: 'CR' },
    { label: 'Cote D\'Ivoire', value: 'CI' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cuba', value: 'CU' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czech Republic', value: 'CZ' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Dominica', value: 'DM' },
    { label: 'Dominican Republic', value: 'DO' },
    { label: 'Ecuador', value: 'EC' },
    { label: 'Egypt', value: 'EG' },
    { label: 'El Salvador', value: 'SV' },
    { label: 'Equatorial Guinea', value: 'GQ' },
    { label: 'Eritrea', value: 'ER' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Ethiopia', value: 'ET' },
    { label: 'Falkland Islands (Malvinas)', value: 'FK' },
    { label: 'Faroe Islands', value: 'FO' },
    { label: 'Fiji', value: 'FJ' },
    { label: 'Finland', value: 'FI' },
    { label: 'France', value: 'FR' },
    { label: 'French Guiana', value: 'GF' },
    { label: 'French Polynesia', value: 'PF' },
    { label: 'French Southern Territories', value: 'TF' },
    { label: 'Gabon', value: 'GA' },
    { label: 'Gambia', value: 'GM' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Germany', value: 'DE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Gibraltar', value: 'GI' },
    { label: 'Greece', value: 'GR' },
    { label: 'Greenland', value: 'GL' },
    { label: 'Grenada', value: 'GD' },
    { label: 'Guadeloupe', value: 'GP' },
    { label: 'Guam', value: 'GU' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Guernsey', value: 'GG' },
    { label: 'Guinea', value: 'GN' },
    { label: 'Guinea-Bissau', value: 'GW' },
    { label: 'Guyana', value: 'GY' },
    { label: 'Haiti', value: 'HT' },
    { label: 'Heard Island and Mcdonald Islands', value: 'HM' },
    { label: 'Holy See (Vatican City State)', value: 'VA' },
    { label: 'Honduras', value: 'HN' },
    { label: 'Hong Kong', value: 'HK' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'India', value: 'IN' },
    { label: 'Indonesia', value: 'ID' },
    { label: 'Iran, Islamic Republic Of', value: 'IR' },
    { label: 'Iraq', value: 'IQ' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Isle of Man', value: 'IM' },
    { label: 'Israel', value: 'IL' },
    { label: 'Italy', value: 'IT' },
    { label: 'Jamaica', value: 'JM' },
    { label: 'Japan', value: 'JP' },
    { label: 'Jersey', value: 'JE' },
    { label: 'Jordan', value: 'JO' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Kenya', value: 'KE' },
    { label: 'Kiribati', value: 'KI' },
    { label: 'Korea, Democratic People\'S Republic of', value: 'KP' },
    { label: 'Korea, Republic of', value: 'KR' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kyrgyzstan', value: 'KG' },
    { label: 'Lao People\'S Democratic Republic', value: 'LA' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Lebanon', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Liberia', value: 'LR' },
    { label: 'Libyan Arab Jamahiriya', value: 'LY' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Macao', value: 'MO' },
    { label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK' },
    { label: 'Madagascar', value: 'MG' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Malaysia', value: 'MY' },
    { label: 'Maldives', value: 'MV' },
    { label: 'Mali', value: 'ML' },
    { label: 'Malta', value: 'MT' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Martinique', value: 'MQ' },
    { label: 'Mauritania', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Mayotte', value: 'YT' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Micronesia, Federated States of', value: 'FM' },
    { label: 'Moldova, Republic of', value: 'MD' },
    { label: 'Monaco', value: 'MC' },
    { label: 'Mongolia', value: 'MN' },
    { label: 'Montserrat', value: 'MS' },
    { label: 'Morocco', value: 'MA' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Myanmar', value: 'MM' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Nauru', value: 'NR' },
    { label: 'Nepal', value: 'NP' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'Netherlands Antilles', value: 'AN' },
    { label: 'New Caledonia', value: 'NC' },
    { label: 'New Zealand', value: 'NZ' },
    { label: 'Nicaragua', value: 'NI' },
    { label: 'Niger', value: 'NE' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Niue', value: 'NU' },
    { label: 'Norfolk Island', value: 'NF' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Norway', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'Pakistan', value: 'PK' },
    { label: 'Palau', value: 'PW' },
    { label: 'Palestinian Territory, Occupied', value: 'PS' },
    { label: 'Panama', value: 'PA' },
    { label: 'Papua New Guinea', value: 'PG' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Philippines', value: 'PH' },
    { label: 'Pitcairn', value: 'PN' },
    { label: 'Poland', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Reunion', value: 'RE' },
    { label: 'Romania', value: 'RO' },
    { label: 'Russian Federation', value: 'RU' },
    { label: 'RWANDA', value: 'RW' },
    { label: 'Saint Helena', value: 'SH' },
    { label: 'Saint Kitts and Nevis', value: 'KN' },
    { label: 'Saint Lucia', value: 'LC' },
    { label: 'Saint Pierre and Miquelon', value: 'PM' },
    { label: 'Saint Vincent and the Grenadines', value: 'VC' },
    { label: 'Samoa', value: 'WS' },
    { label: 'San Marino', value: 'SM' },
    { label: 'Sao Tome and Principe', value: 'ST' },
    { label: 'Saudi Arabia', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbia and Montenegro', value: 'CS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Sierra Leone', value: 'SL' },
    { label: 'Singapore', value: 'SG' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Solomon Islands', value: 'SB' },
    { label: 'Somalia', value: 'SO' },
    { label: 'South Africa', value: 'ZA' },
    { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sri Lanka', value: 'LK' },
    { label: 'Sudan', value: 'SD' },
    { label: 'Surilabel', value: 'SR' },
    { label: 'Svalbard and Jan Mayen', value: 'SJ' },
    { label: 'Swaziland', value: 'SZ' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Syrian Arab Republic', value: 'SY' },
    { label: 'Taiwan, Province of China', value: 'TW' },
    { label: 'Tajikistan', value: 'TJ' },
    { label: 'Tanzania, United Republic of', value: 'TZ' },
    { label: 'Thailand', value: 'TH' },
    { label: 'Timor-Leste', value: 'TL' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tokelau', value: 'TK' },
    { label: 'Tonga', value: 'TO' },
    { label: 'Trinidad and Tobago', value: 'TT' },
    { label: 'Tunisia', value: 'TN' },
    { label: 'Turkey', value: 'TR' },
    { label: 'Turkmenistan', value: 'TM' },
    { label: 'Turks and Caicos Islands', value: 'TC' },
    { label: 'Tuvalu', value: 'TV' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'United Kingdom', value: 'GB' },
    { label: 'United States', value: 'US' },
    { label: 'United States Minor Outlying Islands', value: 'UM' },
    { label: 'Uruguay', value: 'UY' },
    { label: 'Uzbekistan', value: 'UZ' },
    { label: 'Vanuatu', value: 'VU' },
    { label: 'Venezuela', value: 'VE' },
    { label: 'Viet Nam', value: 'VN' },
    { label: 'Virgin Islands, British', value: 'VG' },
    { label: 'Virgin Islands, U.S.', value: 'VI' },
    { label: 'Wallis and Futuna', value: 'WF' },
    { label: 'Western Sahara', value: 'EH' },
    { label: 'Yemen', value: 'YE' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' }
]


export const numEmployeesOptions: SelectOption[] = [
    { label: '1-99', value: '1-to-99' },
    { label: '100-999', value: '100-to-999' },
    { label: '1000-4999', value: '1000-to-4999' },
    { label: '5000+', value: '5000-and-above' },
]

export const serviceProviderOption: SelectOption[] = [
    { label: 'Influencer Marketing', value: "1" },
    { label: 'Social Media Marketing', value: "2" },
    { label: 'Event Organisations', value: "3" },
    { label: 'Others', value: "4" }
]

export const roleOption: SelectOption[] = [
    { label: 'Brand', value: "brand" },
    { label: 'Agency', value: "agency" },
    { label: 'Creator', value: "creator" },
]


export const industryOptions: SelectOption[] = [
    { label: 'Accounting', value: 'Accounting' },
    { label: 'Airlines / Aviation', value: 'Airlines / Aviation' },
    { label: 'Alternative Dispute Resolution', value: 'Alternative Dispute Resolution' },
    { label: 'Alternative Medicine', value: 'Alternative Medicine' },
    { label: 'Animation', value: 'Animation' },
    { label: 'Apparel / Fashion', value: 'Apparel / Fashion' },
    { label: 'Architecture / Planning', value: 'Architecture / Planning' },
    { label: 'Arts / Crafts', value: 'Arts / Crafts' },
    { label: 'Automotive', value: 'Automotive' },
    { label: 'Aviation / Aerospace', value: 'Aviation / Aerospace' },
    { label: 'Banking / Mortgage', value: 'Banking / Mortgage' },
    { label: 'Biotechnology / Greentech', value: 'Biotechnology / Greentech' },
    { label: 'Broadcast Media', value: 'Broadcast Media' },
    { label: 'Building Materials', value: 'Building Materials' },
    { label: 'Business Supplies / Equipment', value: 'Business Supplies / Equipment' },
    { label: 'Capital Markets / Hedge Fund / Private Equity', value: 'Capital Markets / Hedge Fund / Private Equity' },
    { label: 'Chemicals', value: 'Chemicals' },
    { label: 'Civic / Social Organization', value: 'Civic / Social Organization' },
    { label: 'Civil Engineering', value: 'Civil Engineering' },
    { label: 'Commercial Real Estate', value: 'Commercial Real Estate' },
    { label: 'Computer Games', value: 'Computer Games' },
    { label: 'Computer Hardware', value: 'Computer Hardware' },
    { label: 'Computer Networking', value: 'Computer Networking' },
    { label: 'Computer Software / Engineering', value: 'Computer Software / Engineering' },
    { label: 'Computer / Network Security', value: 'Computer / Network Security' },
    { label: 'Construction', value: 'Construction' },
    { label: 'Consumer Electronics', value: 'Consumer Electronics' },
    { label: 'Consumer Goods', value: 'Consumer Goods' },
    { label: 'Consumer Services', value: 'Consumer Services' },
    { label: 'Cosmetics', value: 'Cosmetics' },
    { label: 'Dairy', value: 'Dairy' },
    { label: 'Defense / Space', value: 'Defense / Space' },
    { label: 'Design', value: 'Design' },
    { label: 'E - Learning', value: 'E - Learning' },
    { label: 'Education Management', value: 'Education Management' },
    { label: 'Electrical / Electronic Manufacturing', value: 'Electrical / Electronic Manufacturing' },
    { label: 'Entertainment / Movie Production', value: 'Entertainment / Movie Production' },
    { label: 'Environmental Services', value: 'Environmental Services' },
    { label: 'Events Services', value: 'Events Services' },
    { label: 'Executive Office', value: 'Executive Office' },
    { label: 'Facilities Services', value: 'Facilities Services' },
    { label: 'Farming', value: 'Farming' },
    { label: 'Financial Services', value: 'Financial Services' },
    { label: 'Fine Art', value: 'Fine Art' },
    { label: 'Fishery', value: 'Fishery' },
    { label: 'Food Production', value: 'Food Production' },
    { label: 'Food / Beverages', value: 'Food / Beverages' },
    { label: 'Fundraising', value: 'Fundraising' },
    { label: 'Furniture', value: 'Furniture' },
    { label: 'Gambling / Casinos', value: 'Gambling / Casinos' },
    { label: 'Glass / Ceramics / Concrete', value: 'Glass / Ceramics / Concrete' },
    { label: 'Government Administration', value: 'Government Administration' },
    { label: 'Government Relations', value: 'Government Relations' },
    { label: 'Graphic Design / Web Design', value: 'Graphic Design / Web Design' },
    { label: 'Health / Fitness', value: 'Health / Fitness' },
    { label: 'Higher Education / Acadamia', value: 'Higher Education / Acadamia' },
    { label: 'Hospital / Health Care', value: 'Hospital / Health Care' },
    { label: 'Hospitality', value: 'Hospitality' },
    { label: 'Human Resources / HR', value: 'Human Resources / HR' },
    { label: 'Import / Export', value: 'Import / Export' },
    { label: 'Individual / Family Services', value: 'Individual / Family Services' },
    { label: 'Industrial Automation', value: 'Industrial Automation' },
    { label: 'Information Services', value: 'Information Services' },
    { label: 'Information Technology / IT', value: 'Information Technology / IT' },
    { label: 'Insurance', value: 'Insurance' },
    { label: 'International Affairs', value: 'International Affairs' },
    { label: 'International Trade / Development', value: 'International Trade / Development' },
    { label: 'Internet', value: 'Internet' },
    { label: 'Investment Banking / Venture', value: 'Investment Banking / Venture' },
    { label: 'Investment Management / Hedge Fund / Private Equity', value: 'Investment Management / Hedge Fund / Private Equity' },
    { label: 'Judiciary', value: 'Judiciary' },
    { label: 'Law Enforcement', value: 'Law Enforcement' },
    { label: 'Law Practice / Law Firms', value: 'Law Practice / Law Firms' },
    { label: 'Legal Services', value: 'Legal Services' },
    { label: 'Legislative Office', value: 'Legislative Office' },
    { label: 'Leisure / Travel', value: 'Leisure / Travel' },
    { label: 'Library', value: 'Library' },
    { label: 'Logistics / Procurement', value: 'Logistics / Procurement' },
    { label: 'Luxury Goods / Jewelry', value: 'Luxury Goods / Jewelry' },
    { label: 'Machinery', value: 'Machinery' },
    { label: 'Management Consulting', value: 'Management Consulting' },
    { label: 'Maritime', value: 'Maritime' },
    { label: 'Market Research', value: 'Market Research' },
    { label: 'Marketing / Advertising / Sales', value: 'Marketing / Advertising / Sales' },
    { label: 'Mechanical or Industrial Engineering', value: 'Mechanical or Industrial Engineering' },
    { label: 'Media Production', value: 'Media Production' },
    { label: 'Medical Equipment', value: 'Medical Equipment' },
    { label: 'Medical Practice', value: 'Medical Practice' },
    { label: 'Mental Health Care', value: 'Mental Health Care' },
    { label: 'Military Industry', value: 'Military Industry' },
    { label: 'Mining / Metals', value: 'Mining / Metals' },
    { label: 'Motion Pictures / Film', value: 'Motion Pictures / Film' },
    { label: 'Museums / Institutions', value: 'Museums / Institutions' },
    { label: 'Music', value: 'Music' },
    { label: 'Nanotechnology', value: 'Nanotechnology' },
    { label: 'Newspapers / Journalism', value: 'Newspapers / Journalism' },
    { label: 'Non - Profit / Volunteering', value: 'Non - Profit / Volunteering' },
    { label: 'Oil / Energy / Solar / Greentech', value: 'Oil / Energy / Solar / Greentech' },
    { label: 'Online Publishing', value: 'Online Publishing' },
    { label: 'Outsourcing / Offshoring', value: 'Outsourcing / Offshoring' },
    { label: 'Package / Freight Delivery', value: 'Package / Freight Delivery' },
    { label: 'Packaging / Containers', value: 'Packaging / Containers' },
    { label: 'Paper / Forest Products', value: 'Paper / Forest Products' },
    { label: 'Performing Arts', value: 'Performing Arts' },
    { label: 'Pharmaceuticals', value: 'Pharmaceuticals' },
    { label: 'Philanthropy', value: 'Philanthropy' },
    { label: 'Photography', value: 'Photography' },
    { label: 'Plastics', value: 'Plastics' },
    { label: 'Political Organization', value: 'Political Organization' },
    { label: 'Primary / Secondary Education', value: 'Primary / Secondary Education' },
    { label: 'Printing', value: 'Printing' },
    { label: 'Professional Training', value: 'Professional Training' },
    { label: 'Program Development', value: 'Program Development' },
    { label: 'Public Relations / PR', value: 'Public Relations / PR' },
    { label: 'Public Safety', value: 'Public Safety' },
    { label: 'Publishing Industry', value: 'Publishing Industry' },
    { label: 'Railroad Manufacture', value: 'Railroad Manufacture' },
    { label: 'Ranching', value: 'Ranching' },
    { label: 'Real Estate / Mortgage', value: 'Real Estate / Mortgage' },
    { label: 'Recreational Facilities / Services', value: 'Recreational Facilities / Services' },
    { label: 'Religious Institutions', value: 'Religious Institutions' },
    { label: 'Renewables / Environment', value: 'Renewables / Environment' },
    { label: 'Research Industry', value: 'Research Industry' },
    { label: 'Restaurants', value: 'Restaurants' },
    { label: 'Retail Industry', value: 'Retail Industry' },
    { label: 'Security / Investigations', value: 'Security / Investigations' },
    { label: 'Semiconductors', value: 'Semiconductors' },
    { label: 'Shipbuilding', value: 'Shipbuilding' },
    { label: 'Sporting Goods', value: 'Sporting Goods' },
    { label: 'Sports', value: 'Sports' },
    { label: 'Staffing / Recruiting', value: 'Staffing / Recruiting' },
    { label: 'Supermarkets', value: 'Supermarkets' },
    { label: 'Telecommunications', value: 'Telecommunications' },
    { label: 'Textiles', value: 'Textiles' },
    { label: 'Think Tanks', value: 'Think Tanks' },
    { label: 'Tobacco', value: 'Tobacco' },
    { label: 'Translation / Localization', value: 'Translation / Localization' },
    { label: 'Transportation', value: 'Transportation' },
    { label: 'Utilities', value: 'Utilities' },
    { label: 'Venture Capital / VC', value: 'Venture Capital / VC' },
    { label: 'Veterinary', value: 'Veterinary' },
    { label: 'Warehousing', value: 'Warehousing' },
    { label: 'Wholesale', value: 'Wholesale' },
    { label: 'Wine / Spirits', value: 'Wine / Spirits' },
    { label: 'Wireless', value: 'Wireless' },
    { label: 'Writing / Editing', value: 'Writing / Editing' },
    { label: 'Other Industry', value: 'Other Industry' },
]

