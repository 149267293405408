import { AxiosError, AxiosStatic } from "axios";
import EmpBadRequestException from "../exception/empBadRequestException";
import EmpException from "../exception/empException";
import EmpInternalServerError from "../exception/empInternalServerError";
import { ApiResponse } from "../model/api/api-response";


const HTTP_CODE = {
    OK: 200,
    CREATED: 201,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
}

/* author : Felix Wang
* description: This is a global error interceptor class that handles exceptions from different status codes.
* This utility class writes interceptior logic ontop the "AxiosStatic" object.
* You can utilize this class by calling```OneNSApiErrorInterceptor.configureInterceptor(axios)```
*/
export class EmpInterceptor {

    /* Static function to configure default error handlers to axios object.
     * @param axios
     */
    static configureInterceptor(axios: AxiosStatic) {
        axios.interceptors.response.use(
            response => {
                return response
            },
            async (error: AxiosError) => {
                console.log("EXCEPTION RECEIVED")
                console.log(error)
                // NetworkError -- this occurs when target host is unreachable
                if (error instanceof Error) {
                    // NetworkError -- this occurs when the target host is unreachable
                    if (error.message === 'Network Error') {
                        return new EmpException("Network Error")
                    }
                }
                const status = error.response?.status;
                const data = error.response?.data;

                if (!status || !data) return

                if (status === HTTP_CODE.BAD_REQUEST) {
                    console.log("Bad Request")
                    const badRequest = data as ApiResponse<undefined>
                    return new EmpBadRequestException(badRequest.error!.message)
                }
                if (status === HTTP_CODE.INTERNAL_SERVER_ERROR) {
                    console.log("Internal Server Error")
                    const internalServerError = data as ApiResponse<undefined>
                    return new EmpInternalServerError(internalServerError.error!.message, internalServerError.error!.title)
                }
                // status
                return new EmpException("An Unexpected Error Occurred")
            });
    }


    /**
     * Static function throw any error from 'configure interceptor'
     * @param e - Might be data
     */
    static handleError(e: any) {
        if (e instanceof EmpException) {
            throw e;
        }
    }
}