import { BaseValidator, IValidator } from "./baseValidation";
import { IFormControl } from "./formControl";
import { VALIDATION_MESSAGE } from "./validationConstants";

export class RequiredValidator extends BaseValidator implements IValidator {

    customErrorMessage: string = VALIDATION_MESSAGE.IS_REQUIRED

    /**
     * @purpose Override error message if necessary
     * @param errorMessage Custom error message that
     * overrides default Required Validator error message
     */
    constructor(errorMessage?: string) {
        super();
        if (errorMessage) this.customErrorMessage = errorMessage
    }

   
    validate(formControl: IFormControl): boolean {
        if (formControl.formType === "text") {
            return this.validateText(formControl)
        }
        else if (formControl.formType === "checkbox-group") {
            return this.validateCheckboxGroup(formControl)
        }
        throw new Error("The requiredValidator is unable to validate this form type");
    }

    validateCheckboxGroup(formControl: IFormControl): boolean {
        const evaluable = formControl.value as string[]

        if (evaluable.length <= 0) {
            this.hasError(formControl, this.customErrorMessage)
            return false
        }
        this.noError(formControl)
        return true
    }

    validateText(formControl: IFormControl): boolean {
        const value = formControl.value
        const evaluable = value ? value.trim() : ''

        if (evaluable.length <= 0 || evaluable === "") {
            this.hasError(formControl, this.customErrorMessage)
            return false
        }
        this.noError(formControl)
        return true
    }

}