import { useInView } from "framer-motion";
import { useEffect } from "react";


const useWaitlistCardAnimation = (ref: React.RefObject<HTMLDivElement>) => {

    const inView = useInView(ref, { amount: 0.8 });

    useEffect(() => {
        if (inView) {
            ref.current?.classList.add("added")
        } else {
            ref.current?.classList.remove("added")
        }
    }, [inView, ref])

    return {
        ref,
    }
}
export default useWaitlistCardAnimation;