import axios from "axios"
import { ApiResponse } from "../model/api/api-response"
import { ApiStatus } from "../model/api/api-status";
import { WaitlistDto } from "../model/waitlist/waitlist.dto"
import { EmpInterceptor } from "./emp-interceptor";



const headers = {
    'Content-Type': 'application/json',
    'x-api-key': "PbvHDYLepw24JCLs5wNCS4fGBiM3HztY46odSMra",
};
EmpInterceptor.configureInterceptor(axios)
const createWaitlistUrl = "https://pijcsyofjh.execute-api.ap-southeast-1.amazonaws.com/default/emp-landing-create-waitlist"
const WaitlistApi = {

    createWaitlistEntry: async (request: WaitlistDto): Promise<ApiStatus> => {
        const response = await axios.post<ApiStatus>(createWaitlistUrl, request, {
            headers: headers,
        })
        EmpInterceptor.handleError(response)
        return response.data;
    }
}

export default WaitlistApi