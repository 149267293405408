import { FormControl } from "./formControl"

export interface IFormGroup { [key: string]: FormControl }



export class FormGroupUtil {
    /**
     * Validates current all form controls, and indicate whether if there is an error or not
     * @returns True, if there is no error, false otherwise
     */
    static validate(formGroup: IFormGroup) {
        let isValid = true;
        for (const value of Object.values(formGroup)) {
            const formValueIsValid = value.validate()
            isValid = isValid && formValueIsValid
        }
        return isValid
    }

    /**
     * Runs validation in all child form control, and indicates whether there is a
     * change in state
     * @returns True, if at least one of the form control experienced a state change. False otherwise.
     */
    static validateTrackDiff(formGroup: IFormGroup): boolean {
        let hasDiff = false;
        for (const value of Object.values(formGroup)) {
            const formValueIsValid = value.validate()
            hasDiff = hasDiff || formValueIsValid
        }
        return hasDiff
    }

    /**
     * Runs validation in all child form control, and indicates whether all fields are pristine.
     * if Pristing, return true, otherwise false
     * @returns True, if form has been untouched. False otherwise
     */
    static isPristine(formGroup: IFormGroup): boolean {
        let pristine = true
        for (const formControl of Object.values(formGroup)) {
            const formControlPristing = formControl.isPristine()
            pristine = pristine && formControlPristing
        }
        return pristine
    }



    /**
     * Reset all form controls in a form group
     */
    static reset(formGroup: IFormGroup) {
        for (const formControl of Object.values(formGroup)) {
           formControl.reset()
        }
    }


    static details(formGroup: IFormGroup) {
        for (const [key, formControl] of Object.entries(formGroup)) {
           console.log(`${key} : ${formControl.value}`)
        }
    }

}