import "./contact-us-page.scss";

import { useRef, useState } from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";

import checkIcon from "../../assets/images/check-3d-icon.png";
import EmpTextInput from "../../components/shared/emp-text-input";
import { EmailValidator } from "../../utilities/formUtils/emailValidator";
import { FormControl } from "../../utilities/formUtils/formControl";
import { FormGroupUtil, IFormGroup } from "../../utilities/formUtils/formGroup";
import { LengthValidator } from "../../utilities/formUtils/lengthValidator";
import { RequiredValidator } from "../../utilities/formUtils/requiredValidator";
import EmpButton, { EmpButtonRef } from "../../components/shared/emp-button";
import useEmpBreakpoint from "../../hooks/useEmpBreakpoint";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import EnquiryAPI from "../../api/enquiry-api";
import EmpExceptionHandler from "../../utilities/errorUtils/empExceptionHandler";
import EmpException from "../../exception/empException";
import useScrollToTop from "../../hooks/useScrollToTop";
import { GtagUtils } from "../../utilities/gtag-utils";

const ContactUsPage = () => {
  useScrollToTop();
  const pageWrapperRef = useRef<HTMLDivElement>(null);
  const isMobile = useEmpBreakpoint(550);
  const submitButtonRef = useRef<EmpButtonRef>();

  const [viewMode, setViewMode] = useState<"form" | "feedback">("form");

  const enterpriseForm: IFormGroup = {
    fullName: new FormControl("text", [
      new RequiredValidator("Name is required"),
      new LengthValidator(
        0,
        100,
        undefined,
        "Name must not exceed 100 characters"
      ),
    ]),

    email: new FormControl("text", [
      new RequiredValidator("Email is required"),
      new LengthValidator(
        0,
        100,
        undefined,
        "Email must not exceed 100 characters"
      ),
      new EmailValidator(),
    ]),

    message: new FormControl("text", [
      new RequiredValidator("Message is required"),
      new LengthValidator(
        0,
        1000,
        undefined,
        "Message must not exceed 1000 characters"
      ),
    ]),
  };

  const [form, setForm] = useState<IFormGroup>(enterpriseForm);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const submitForm = async () => {
    try {
      if (!executeRecaptcha) {
        throw new EmpException("Recaptcha is not ready");
      }
      submitButtonRef.current?.setButtonState("loading");
      const isValid = FormGroupUtil.validate(form);
      setForm({ ...form });
      if (!isValid) return;

      const token = await executeRecaptcha(
        "6LciZM0kAAAAACZvn1nzn6wstUvmx9lDTbJMF79I"
      );
      const request = {
        name: form.fullName.getValue(),
        email: form.email.getValue(),
        message: form.message.getValue(),
        token,
      };
      GtagUtils.logContactUsBtnEvent();
      GtagUtils.contactUsFormSubmissionEvent(
        form.fullName.getValue(),
        form.email.getValue(),
        form.message.getValue()
      );

      const response = await EnquiryAPI.createEnquiry(request);
      if (response.status === "success") {
        setViewMode("feedback");
      }
    } catch (e) {
      console.error(e);
      if (!(e instanceof Error)) return;
      EmpExceptionHandler.builder()
        .handleCommonlHttpErrors()
        .handleGenericError()
        .build()
        .process(e);
    } finally {
      submitButtonRef.current?.setButtonState("default");
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Contact Us - Get in Touch with Our Expert Team | Emplifive
        </title>
        <meta
          name="description"
          content="Contact our expert team today to learn more about Emplifive, and how we can help your business grow. Get in touch now to schedule a consultation and discover the power of our Emplifive."
        />
      </Helmet>
      <div className="emp-contact-us-page" ref={pageWrapperRef}>
        <section>
          <div className="section-wrapper">
            <div className="content-section">
              {!isMobile && (
                <h2 className="header">
                  Looking to streamline your marketing efforts and achieve
                  better results? 🔥{" "}
                </h2>
              )}
              {isMobile && <h2 className="header">Let's get in touch 🤝 </h2>}
              <p className="description">
                We are happy to share with you how you can leverage Emplifive
                today to help you reach your target audience
              </p>
            </div>
            <div className="form-section">
              <div className="form-card">
                <h1 className="header">Contact us</h1>
                <p className="description">
                  Get in touch and let us know how we can help.
                </p>
                {viewMode === "form" && (
                  <>
                    <div className="mt-6">
                      <EmpTextInput
                        id={"fullName"}
                        placeholder={"Jane Doe"}
                        labelText="Name"
                        type="email"
                        formControl={form.fullName}
                        required
                      />
                    </div>
                    <div className="mt-6">
                      <EmpTextInput
                        id={"email"}
                        placeholder={"jane.doe@example.com"}
                        labelText="Email Address"
                        required
                        formControl={form.email}
                      />
                    </div>
                    <div className="mt-6">
                      <EmpTextInput
                        id={"message"}
                        placeholder={"Enter your message"}
                        labelText="Message"
                        rows={3}
                        multiline
                        characterCount={1000}
                        required
                        formControl={form.message}
                      />
                      <p className="description">
                        This site is protected by reCAPTCHA and the Google
                        &nbsp;
                        <a
                          href="https://policies.google.com/privacy"
                          rel="noreferrer"
                          target="_blank"
                        >
                          Privacy Policy
                        </a>{" "}
                        and &nbsp;
                        <a
                          href="https://policies.google.com/terms"
                          rel="noreferrer"
                          target="_blank"
                        >
                          Terms of Service
                        </a>{" "}
                        apply.
                      </p>
                    </div>
                    <div className="mt-6 form-btn-row">
                      <div className="btn-width-wrapper">
                        <EmpButton
                          onSubmit={() => {
                            submitForm();
                          }}
                          text="Send Message"
                          ref={submitButtonRef}
                        />
                      </div>
                    </div>
                  </>
                )}

                {viewMode === "feedback" && (
                  <div className="contact-us-feedback-section">
                    <motion.img
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                      className="check-btn"
                      src={checkIcon}
                    />
                    <motion.p
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: 0.2 }}
                      className="feedback-message"
                    >
                      Thank you for contacting us! We have received your message
                      and will get back to you as soon as possible. Have a great
                      day!
                    </motion.p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ContactUsPage;
