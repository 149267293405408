import { BaseValidator, IValidator } from "./baseValidation";
import { FormControl } from "./formControl";

export class LengthValidator extends BaseValidator implements IValidator {


    minLength
    maxLength
    customMinErrorMsg?: string = undefined
    customMaxErrorMsg?: string = undefined


    constructor(minLength: number, maxLength: number, customMinErrorMsg?: string, customMaxErrorMsg?: string) {
        super();
        this.minLength = minLength
        this.maxLength = maxLength
        this.customMinErrorMsg = customMinErrorMsg
        this.customMaxErrorMsg = customMaxErrorMsg
    }

    /**
     * @purpose Perform valdiation based on supplied min length and max length
     * @param formControl: IFormControl
     * @returns True if valid | False if invalid
     */
    validate(formControl: FormControl): boolean {
        const DEFAULT_MIN_ERR = `Minumum of ${this.minLength} characters allowed`
        const DEFAULT_MAX_ERR = `You can't exceed ${this.maxLength} characters`

        const minErrMsg = this.customMinErrorMsg === undefined ? DEFAULT_MIN_ERR : this.customMinErrorMsg
        const maxErrMsg = this.customMaxErrorMsg === undefined ? DEFAULT_MAX_ERR : this.customMaxErrorMsg

        const value = formControl.getValue()
        const evaluable = value ? value.trim() : ''

        if (evaluable.length < this.minLength) {
            this.hasError(formControl, minErrMsg)
            return false
        } else if (evaluable.length > this.maxLength) {
            this.hasError(formControl, maxErrMsg)
            return false
        }
        this.noError(formControl)
        return true
    }
}