import { useRef } from "react";
import "./emp-footer.scss";
import { Link, useNavigate } from "react-router-dom";

const socialMediaIconPaths: { [key: string]: string } = {
  instagram:
    "M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z",
  // Add more icons as needed
};

const EmpFooter = () => {
  const navigate = useNavigate();

  const pageWrapperRef = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const socialMediaLinks = [
    { url: "https://instagram.com/emerge_grp", icon: "instagram" },
    // Add more social media links as needed
  ];

  const navigationLinks = [
    { to: "/", label: "Home" },
    { to: "features", label: "Features" },
    { to: "pricing", label: "Pricing" },
    // { to: "about-us", label: "About Us" },
    { to: "contact-us", label: "Contact Us" },
    // { to: "enterprise-interest", label: "Request a Demo" },
    { to: "legal#privacy-policy", label: "Privacy Policy" },
    { to: "legal#terms-of-service", label: "Terms of Service" },
    { to: "legal#refund-policy", label: "Refund Policy" },
    { to: "legal#localisation-doc", label: "Currency Localisation Usage Policy" },
  ];
  const NavigationSection = ({
    links,
  }: {
    links: { to: string; label: string }[];
  }) => (
    <nav>
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <Link to={link.to} onClick={scrollToTop}>
              <span>{link.label}</span>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );

  return (
    <>
      <footer className="emp-footer" ref={pageWrapperRef}>
        <section className="emp-footer-contain">
          <div className="footer-section-top">
            <div className="logo-wrapper">
              <img
                onClick={() => {
                  navigate("/");
                  scrollToTop();
                }}
                className="logo"
                alt="Emplifive logo"
                src={
                  "https://emplifive-dev-bucket.s3.ap-southeast-1.amazonaws.com/public-images/emplifive-logo-black.svg"
                }
              />
            </div>
            <div>
              <NavigationSection links={navigationLinks.slice(0, 3)} />
              <NavigationSection links={navigationLinks.slice(3, 8)} />
            </div>
          </div>

          {/* <div className="footer-section-bottom">
            <p className="copyright">© EMERGE Group</p>
            <div className="footer-socials">
              <p>Follow us on social media:</p>
              <div className="social-icons">
                {socialMediaLinks.map((link, index) => (
                  <Link key={index} to={link.url} target="_blank">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      width="24"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="#010E21"
                        d={socialMediaIconPaths[link.icon]}
                      />
                    </svg>
                  </Link>
                ))}
              </div>
            </div>
          </div> */}
        </section>
      </footer>
    </>
  );
};
export default EmpFooter;
