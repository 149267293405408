export const contentChildVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.2 } },
};

export const contentWrapperVariants = {
  hidden: { opacity: 0, translateX: -30 },
  visible: {
    opacity: 1,
    translateX: 0,
    transition: { delayChildren: 0.2, staggerChildren: 0.1 },
  },
};
