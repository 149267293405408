import { BaseValidator, IValidator } from "./baseValidation";
import { FormControl } from "./formControl";
import { VALIDATION_MESSAGE } from "./validationConstants";


export class EmailValidator extends BaseValidator implements IValidator {

    /**
    * @purpose Perform email address validation
    * @param value Evaluated String
    * @returns True if valid | False if invalid
    */
    validate(formControl: FormControl): boolean {
        const regex = /^([0-9a-zA-Z]([-_\\.\\+]*[0-9a-zA-Z]+)*)@([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)[\\.]([a-zA-Z]{2,9})$/;
        // const regex = /^([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)@([0-9a-zA-Z]([-_\\.]*[0-9a-zA-Z]+)*)[\\.]([a-zA-Z]{2,9})$/;
        const value = formControl.getValue()
        const evaluable = value ? value.trim() : ''

        if (evaluable.length <= 0) {
            this.hasError(formControl, VALIDATION_MESSAGE.IS_REQUIRED)
            return false
        }
        else if (!regex.test(evaluable)) {
            this.hasError(formControl, VALIDATION_MESSAGE.EMAIL_INVALID)
            return false
        }
        else if (evaluable.length > 100) {
            this.hasError(formControl, VALIDATION_MESSAGE.EMAIL_TOO_LONG)
            return false
        }
        else {
            this.noError(formControl)
            return true
        }

    }
}