import { EXCEPTION_TYPE } from "../constants";
import EmpException from "./empException";

class EmpInternalServerError extends EmpException {
  type: string;
  title: string;

  constructor(message: string, title: string) {
    super(message);
    this.type = EXCEPTION_TYPE.INTERNAL_SERVER_ERROR;
    this.title = title;
    this.name = "EmpInternalServerError";
  }
}
export default EmpInternalServerError;
