

export class GtagUtils {
  static readonly logScheduleDemoBtnEvent = () => {
    gtag("event", "click_schedule_btn", {
      content_id: "schedule_btn",
      content_type: "button",
    });
  };

  static readonly logSignUpBtnEvent = () => {
    gtag("event", "click_signup_btn", {
      content_id: "signup_btn",
      content_type: "button",
    });
  };

  static readonly logLoginBtnEvent = () => {
    gtag("event", "click_login_btn", {
      content_id: "login_btn",
      content_type: "button",
    });
  };

  static readonly logContactUsBtnEvent = () => {
    gtag("event", "click_contact_us_submit_btn", {
      content_id: "contact_us_submit_btn",
      content_type: "button",
    });
  };

  static readonly contactUsFormSubmissionEvent = (
    name: string,
    email: string,
    message: string
  ) => {
    gtag("event", "click_contact_us_submission", {
      name: name,
      email: email,
      message: message,
    });
  };
}
