import "./404-page.scss";

import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import EmpButton from "../../components/shared/emp-button";

type Props = {};

const NotFoundPage = (props: Props) => {
  const navigate = useNavigate();

  return (
    <div className="not-found-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>404 Not Found</title>
        <meta
          name="description"
          content="The page you are looking for does not exist."
        />
        ƒ
      </Helmet>
      <section>
        <div className="content">
          <h1>404 - Page Not Found</h1>
          <p>
            Oops! The page you are looking for might be in another dimension.
          </p>
          <div>
            <EmpButton
              text={"Back to Homepage"}
              buttonHeight="xl"
              onSubmit={() => {
                navigate("/");
              }}
              isFullWidth={false}
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default NotFoundPage;
