import {IconProps} from "../../model/common/icon"
import useEmpIcon from "../../hooks/useEmpIcon";

const EyeIcon = (props: IconProps) => {
    const { size, top, left, right, bottom, backgroundColor } = useEmpIcon(props)
    return (
        <div style={{ position: "relative", top, left, right, bottom, display: "flex", alignItems: "center" }}>
            <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z" stroke={backgroundColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z" stroke={backgroundColor} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </div >)
}
export default EyeIcon