import "./emp-landing-navbar.scss";

import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import useEmpBreakpoint from "../../../hooks/useEmpBreakpoint";
import EmpAnimatedMenuBtn, {
  EmpAnimatedMenuBtnRef,
} from "../../shared/emp-animated-menu-btn";
import { ENV_CONSTANTS, getEnv } from "../../../constants";
import NavbarMobileMenuItem from "./navbar-mobile-menu-item";
import NavbarMenuItem from "./navbar-menu-item";
import EmpButton from "../../shared/emp-button";
import { GtagUtils } from "../../../utilities/gtag-utils";

const menuVariant = {
  white: {
    backgroundColor: "#fefefe",
    transition: { duration: 0.2 },
  },
  grey: { backgroundColor: "rgba(250, 250, 250, 0.75)" },
  lightGrey: { backgroundColor: "#111315" },
  transparent: { backgroundColor: "transparent" },
  black: { backgroundColor: "#111315", color: "#fefefe" },
};

type NavModeType = "dynamic" | "static";

const pageToNavConfigMapping: {
  [key: string]: { navMode: NavModeType; variant: string };
} = {
  "": {
    navMode: "static",
    variant: "white",
  },
  blogs: {
    navMode: "static",
    variant: "white",
  },
  blog: {
    navMode: "static",
    variant: "white",
  },
  "enterprise-interest": {
    navMode: "static",
    variant: "lightGrey",
  },
  pricing: {
    navMode: "static",
    variant: "transparent",
  },
  "about-us": {
    navMode: "static",
    variant: "transparent",
  },
  features: {
    navMode: "static",
    variant: "transparent",
  },
  "contact-us": {
    navMode: "static",
    variant: "transparent",
  },
  legal: {
    navMode: "static",
    variant: "transparent",
  },
  waitlist: {
    navMode: "static",
    variant: "black",
  },
  "404": {
    navMode: "static",
    variant: "transparent",
  },
};

const menuItems = [
  { to: "", text: "Home", mobileText: "Home", isNested: false, value: "" },
  {
    to: "features",
    text: "Features",
    mobileText: "Features",
    isNested: false,
    value: "features",
  },
  {
    to: "pricing",
    text: "Pricing",
    mobileText: "Pricing",
    isNested: false,
    value: "pricing",
  },
  {
    to: "resources",
    text: "Resources",
    mobileText: "Resources",
    isNested: true,
    value: "resources",
  },
  // { to: "about-us", text: "About", mobileText: "About Us", isNested: false },
  {
    to: "contact-us",
    text: "Contact",
    mobileText: "Contact Us",
    isNested: false,
    value: "contact-us",
  },
];

const EmpLandingNavbar = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  const mobileMenuBtnRef = useRef<EmpAnimatedMenuBtnRef>();
  const mobileMenuRef = useRef<HTMLDivElement>(null);

  const isMobile = useEmpBreakpoint(700);

  const [mobileMenuIsExpanded, setMobileMenuIsExpanded] =
    useState<boolean>(false);
  const [isMobileMode, setMobileMode] = useState<boolean>(true);
  // Manages the current variant of the dropdown list.
  const [activeVariant, setActiveVariant] = useState("black");
  const [currentPath, setCurrentPath] = useState<string>("");

  // A dynamic navMode animates the navbar bg color when user scroll past the first 100vh
  const navModeRef = useRef<NavModeType>("static");

  useEffect(() => {
    // Format the pathname by removing any trailing digits
    const pathnameFormatted = location.pathname.split("/")[1];
    const config =
      pageToNavConfigMapping[location.pathname] ||
      pageToNavConfigMapping[pathnameFormatted];
    if (!config) return;
    setCurrentPath(pathnameFormatted);
    navModeRef.current = config.navMode;
    setActiveVariant(config.variant);
  }, [location]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setMobileMode(isMobile);
    if (!mobileMenuBtnRef.current || !mobileMenuRef.current) return;
    if (!isMobile) {
      setMobileMenuIsExpanded(false);
      mobileMenuBtnRef.current.toBarIcon();
      mobileMenuRef.current.style.height = "0px";
    }
  }, [isMobile]);

  const handleScroll = () => {
    const navMode = navModeRef.current;
    if (navMode !== "dynamic") return;

    const position = window.pageYOffset;
    if (position > window.innerHeight) {
      setActiveVariant("grey");
    } else {
      setActiveVariant("black");
    }
  };

  //Toggle the mobile menu animated button
  const toggleMobileMenu = () => {
    if (!mobileMenuBtnRef.current || !mobileMenuRef.current) return;
    const isExpanded = !mobileMenuIsExpanded;
    setMobileMenuIsExpanded(isExpanded);
    if (isExpanded) {
      mobileMenuBtnRef.current.toCloseIcon();
      mobileMenuRef.current.style.height = "calc(100vh - 80px)";
    } else {
      mobileMenuBtnRef.current.toBarIcon();
      mobileMenuRef.current.style.height = "0px";
    }
  };

  useEffect(() => {}, []);

  return (
    <motion.div
      variants={menuVariant}
      initial={"white"}
      animate={activeVariant}
      className="emrg-navbar"
    >
      <div className="navbar-content">
        <div className="logo-wrapper">
          <svg
            onClick={() => {
              navigate("/");
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            className="logo"
            xmlns="http://www.w3.org/2000/svg"
            version="1.2"
            viewBox="0 0 1021 211"
            width="140"
            height="28"
          >
            <div
              id="in-page-channel-node-id"
              data-channel-name="in_page_channel_RpC_CX"
            />
            <title>Emplifive Logo</title>
            <defs>
              <linearGradient
                id="g1"
                x1="22.6"
                y1="35.5"
                x2="163"
                y2="176"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#00ffff" />
                <stop offset="1" stopColor="#0057ff" />
              </linearGradient>
            </defs>
            <g id="Layer_1">
              <g id="Layer">
                <path
                  fill="url(#g1)"
                  id="Layer"
                  className="s0"
                  d="m129.8 108l-60.8 35.1c-2.4 1.3-2.4 4.8 0 6.2l15.3 8.8c5.3 3.1 11.7 3.1 17 0l28.5-16.4c5.3-3 11.7-3 17 0l27.9 16.1c2.4 1.4 2.4 4.9 0 6.3l-73.4 42.4c-5.3 3-11.7 3-17 0l-8.3-4.8-29.2-16.9-37-21.3c-5.2-3.1-8.5-8.7-8.5-14.7v-60.5c0-2.8 3-4.5 5.5-3.1l31.9 18.4q2.1 1.2 4.5 1.8c4.2 1 8.6 0.4 12.5-1.8l60.9-35.1c2.4-1.4 2.4-4.9 0-6.3l-15.3-8.8c-5.3-3.1-11.7-3.1-16.9-0.1l-5.9 3.4-6.7 3.9-16.1 9.3c-5.3 3-11.8 3-17 0l-28-16.1c-2.4-1.4-2.4-4.9 0-6.3l73.6-42.5c5.3-3 11.7-3 17 0l8.2 4.8 29.2 16.8 37.1 21.5c5.2 3 8.5 8.6 8.5 14.6v60.7c0 2.8-3 4.5-5.5 3.1l-32-18.5c-1.4-0.8-2.9-1.4-4.4-1.8-4.2-1-8.7-0.5-12.6 1.8z"
                />
              </g>
              <g id="Layer">
                <path
                  id="Layer"
                  className="s1"
                  d="m273.3 74.3v20.9h33.7v19.4h-33.7v22.6h38.1v20.1h-63.2v-103.1h63.2v20.1z"
                />
                <path
                  id="Layer"
                  className="s1"
                  d="m451.9 54.2v103.1h-25.2v-61.8l-23 61.8h-20.3l-23.3-62 0.1 62h-25.2v-103.1h29.7l28.9 71.4 28.7-71.4z"
                />
                <path
                  id="Layer"
                  fillRule="evenodd"
                  className="s1"
                  d="m551.8 103.8q-4.1 7.4-12.7 12-8.5 4.5-21.1 4.5h-15.6v37h-25.1v-103.1h40.7q12.3 0 20.8 4.2 8.6 4.3 12.8 11.8 4.3 7.5 4.3 17.2 0 9-4.1 16.4zm-25-6.9q3.5-3.3 3.5-9.5 0-6.2-3.5-9.6-3.5-3.3-10.7-3.3h-13.7v25.8h13.7q7.2 0 10.7-3.4z"
                />
                <path
                  id="Layer"
                  className="s1"
                  d="m601.4 137.9h32.9v19.4h-58v-103.1h25.1z"
                />
                <path
                  id="Layer"
                  className="s1"
                  d="m678.9 54.2v103.1h-25.2v-103.1z"
                />
                <path
                  id="Layer"
                  className="s1"
                  d="m771.6 54.2v20.1h-42v21.8h31.4v19.5h-31.4v41.7h-25.2v-103.1z"
                />
                <path
                  id="Layer"
                  className="s1"
                  d="m817.3 54.2v103.1h-25.1v-103.1z"
                />
                <path
                  id="Layer"
                  className="s1"
                  d="m939.7 54.2l-36.6 103.1h-31.5l-36.5-103.1h26.7l25.6 77.9 25.7-77.9z"
                />
                <path
                  id="Layer"
                  className="s1"
                  d="m982.6 74.3v20.9h33.6v19.4h-33.6v22.6h38v20.1h-63.1v-103.1h63.1v20.1z"
                />
              </g>
            </g>
          </svg>
        </div>
        <ul>
          {menuItems.map((item, index) => (
            <NavbarMenuItem
              key={index}
              menuItem={item}
              currentPath={currentPath}
            />
          ))}
        </ul>
        {!isMobileMode && (
          <div className="button-wrapper">
            <EmpButton
              text="Login"
              onSubmit={() => {
                GtagUtils.logLoginBtnEvent();
                window.open("https://platform.emplifive.com", "_blank");
              }}
              buttonHeight="sm"
              buttonStyle="secondary"
              isFullWidth={false}
            />

            <EmpButton
              text="Sign up for Free"
              onSubmit={() => {
                GtagUtils.logSignUpBtnEvent();
                window.open("https://platform.emplifive.com/sign-up", "_blank");
              }}
              buttonHeight="sm"
              buttonStyle="secondary"
              isFullWidth={false}
            />
            <EmpButton
              text="Schedule a Demo"
              onSubmit={() => {
                GtagUtils.logScheduleDemoBtnEvent()
                navigate("/contact-us");
              }}
              buttonHeight="sm"
              isFullWidth={false}
            />
          </div>
        )}
        <div className="menu-btn-wrapper ml-4">
          <EmpAnimatedMenuBtn
            ref={mobileMenuBtnRef}
            onClick={() => {
              toggleMobileMenu();
            }}
          />
        </div>
      </div>
      <div ref={mobileMenuRef} className="mobile-menu">
        <ul>
          {menuItems.map((item) => (
            <NavbarMobileMenuItem
              menuItem={item}
              key={item.value}
              currentPath={currentPath}
              toggleMobileMenu={toggleMobileMenu}
            />
          ))}
          <li>
            <button
              className="emp-reset"
              onClick={() => {
                GtagUtils.logScheduleDemoBtnEvent();
                navigate("/contact-us");
                toggleMobileMenu();
              }}
            >
              <span className="color-gray-100">Schedule a Demo</span>
            </button>
          </li>
          <li>
            <button
              className="emp-reset"
              onClick={() => {
                GtagUtils.logLoginBtnEvent();
                window.location.href = ENV_CONSTANTS[getEnv()].emplifiveUrl;
                toggleMobileMenu();
              }}
            >
              <span className="color-gray-100">Login</span>
            </button>
          </li>
          <li>
            <button
              className="emp-reset"
              onClick={() => {
                GtagUtils.logSignUpBtnEvent();
                window.location.href = `${
                  ENV_CONSTANTS[getEnv()].emplifiveUrl
                }/sign-up`;
                toggleMobileMenu();
              }}
            >
              <span className="color-gray-100">Sign up for free</span>
            </button>
          </li>
        </ul>
      </div>
    </motion.div>
  );
};
export default EmpLandingNavbar;
