export const VALIDATION_MESSAGE = {
    IS_REQUIRED : "This field is required",
    EMAIL_INVALID : "Email address is invalid",
    EMAIL_TOO_LONG : "Email address is too long",
    MOBILE_INVALID : "Invalid mobile number",
    NUMERIC_INVALID : "Input needs to be a number",
    TRUE_REQUIRED : "You need to tick the checkbox before continuing",

    PASSWORD_NEED_LOWER_CASE : "Password needs to contain at least 1 lower case character",
    PASSWORD_NEED_UPPER_CASE : "Password needs to contain at least 1 upper case character",
    PASSWORD_NEED_NUMBER : "Password needs at least one numerical character",
    PASSWORD_MIN_LENGTH : "Password needs to be at least 8 characters",


    PATTERN_INVALID: "Specified Value has an invalid pattern"
}