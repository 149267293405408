import { EXCEPTION_TYPE } from "../../constants";
import EmpExceptionHandler from "./empExceptionHandler";

export class EmpExceptionHandlerBuilder {
  errorHandler: EmpExceptionHandler;

  /**
   * Creates a new instance of the error handling builder.
   */
  constructor() {
    this.errorHandler = new EmpExceptionHandler();
  }

  /**
   * Resets the error handling object being built.
   */
  public reset(): void {
    this.errorHandler = new EmpExceptionHandler();
  }

  /**
   * Adds an error handler for HTTP status code 500.
   * @returns {EmpExceptionHandlerBuilder} The error handling builder.
   */
  public handle500(): EmpExceptionHandlerBuilder {
    this.errorHandler.addToHandlerQueue(EXCEPTION_TYPE.INTERNAL_SERVER_ERROR);
    return this;
  }

  /**
   * Adds an error handler for HTTP status code 400.
   */
  public handle400(): EmpExceptionHandlerBuilder {
    this.errorHandler.addToHandlerQueue(EXCEPTION_TYPE.BAD_REQUEST_EXCEPTION);
    return this;
  }

  /**
   * Adds an error handler for generic errors.
   * @returns {EmpExceptionHandlerBuilder} The error handling builder.
   */
  public handleGenericError(): EmpExceptionHandlerBuilder {
    this.errorHandler.addToHandlerQueue(EXCEPTION_TYPE.GENERIC_ERROR);
    return this;
  }

  /**
   * A combination of different Http Errors
   * @returns
   */
  public handleCommonlHttpErrors(): EmpExceptionHandlerBuilder {
    this.errorHandler.addToHandlerQueue(EXCEPTION_TYPE.BAD_REQUEST_EXCEPTION);
    this.errorHandler.addToHandlerQueue(EXCEPTION_TYPE.INTERNAL_SERVER_ERROR);
    return this;
  }

  /**
   * Gets the error handling object being built.
   * @returns {EmpExceptionHandler} The error handling object.
   */
  public build(): EmpExceptionHandler {
    const result = this.errorHandler;
    this.reset();
    return result;
  }
}
export default EmpExceptionHandlerBuilder;
