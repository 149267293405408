export class Color {
  static NEUTRAL = {
    0: "#fefefe",
    50: "#F8F8F9",
    100: "#EFF0F1",
    150: "#DFE0E3",
    200: "#CFD1D6",
    250: "#BFC2C8",
    300: "#AEB3BA",
    350: "#9EA3AC",
    400: "#8E949F",
    450: "#7E8591",
    500: "#6F7682",
    550: "#595E68",
    600: "#4E535B",
    650: "#43474E",
    700: "#383B41",
    750: "#2C2F34",
    800: "#212327",
    850: "#16181A",
    900: "#0B0C0D",
    950: "#010E21",
    1000: "#010E21",
  };

  static PRIMARY = {
    100: "#E9F2FE",
    150: "#D3E4FD",
    200: "#BED7FC",
    250: "#A8C9FB",
    300: "#92BCFB",
    350: "#7CAEFA",
    400: "#67A1F9",
    450: "#5193F8",
    500: "#3B86F7",
    550: "#1A72F6",
    600: "#0961E5",
    650: "#0853C4",
    700: "#0745A3",
    750: "#053783",
    800: "#042A62",
    850: "#031C41",
    900: "#010E21",
  };

  static RED = {
    100: "#FFD2D3",
    200: "#FFA5A7",
    300: "#FF777B",
    400: "#FF4A4F",
    500: "#FF1D23",
    600: "#D50006",
    700: "#8E0004",
    800: "#470002",
  };

  static GREEN = {
    100: "#C2F4D2",
    200: "#84EAA6",
    300: "#47DF79",
    400: "#21BD54",
    500: "#168039",
    600: "#11602B",
    700: "#0B401D",
    800: "#06200E",
  };
}
