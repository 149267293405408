import { forwardRef, useImperativeHandle, useRef } from 'react'
import './emp-animated-menu-btn.scss'

interface Props {
    onClick: () => void
}

export interface EmpAnimatedMenuBtnRef {
    toBarIcon: () => void,
    toCloseIcon: () => void
}
const EmpAnimatedMenuBtn = forwardRef((props: Props, ref) => {


    const topBarElemRef = useRef<HTMLDivElement>(null)
    const middleBarElemRef = useRef<HTMLDivElement>(null)
    const bottomBarElemRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => {
        return {
            toBarIcon,
            toCloseIcon
        };
    }, []);

    const toBarIcon = () => {
        topBarElemRef.current?.classList.remove("top")
        middleBarElemRef.current?.classList.remove("middle")
        bottomBarElemRef.current?.classList.remove("bottom")
    }

    const toCloseIcon = () => {
        topBarElemRef.current?.classList.add("top")
        middleBarElemRef.current?.classList.add("middle")
        bottomBarElemRef.current?.classList.add("bottom")
    }

    return (
        <div onClick={() => { props.onClick() }} className='btn-wrapper'>
            <div ref={topBarElemRef} className='bar'></div>
            <div ref={middleBarElemRef} className='bar'></div>
            <div ref={bottomBarElemRef} className='bar'></div>
        </div>
    )
})
export default EmpAnimatedMenuBtn