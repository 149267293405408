import { ENV_CONSTANTS, getEnv } from "./envConstants";

console.log(ENV_CONSTANTS[getEnv()]);
const adminMsvcUrl = ENV_CONSTANTS[getEnv()].adminMsvc;
const lambdaUrl = "lambda.com";
export const ENDPOINTS = {
  leads: {
    createEnterpriseLead: `${adminMsvcUrl}/leads/enterprise`,
    createServiceProviderLead: `${adminMsvcUrl}/leads/service-provider`,
  },
  enquiry: {
    lambdaUrl:
      "https://kmfytwschlapa4qcabvg3vwo4m0fdqti.lambda-url.ap-southeast-1.on.aws/",
  },
};
